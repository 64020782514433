<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <h1 class="brand-text text-primary ml-1">
        Daisy
      </h1>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <div
          class="alert alert-primary info mb-2"
          role="alert"
        >
          <h4 class="alert-heading">DÉMARRAGE</h4>
          <div class="alert-body">
            Bonjour et bienvenue sur Daisy, le projet RESSIFNET IST . <br/>
            En cas de problème, merci de contacter votre <span class="font-weight-bolder">référent logiciel</span>
          </div>
        </div>

        <h2 class="mb-1">
          Nous sommes parés au décollage 🚀
        </h2>
        <p class="mb-3">
          Pour lancer l'application, c'est juste en dessous !
        </p>

        <b-button
          variant="colorwave"
          size="lg"
          block
          class="mb-1 btn-md-block"
          type="submit"
          @click="startApp()"
        >
          <b>
            Me connecter
          </b>
        </b-button>

        <b-spinner v-if="loginPrompted" label="Loading..." />

        <b-img
          fluid
          :src="imgUrl"
          alt="Coming soon page"
        />
      </div>
    </div>
  </div>
<!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BButton, BImg, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    BSpinner
  },
  data() {
    return {
      loginPrompted: false,
      downImg: require('@/assets/images/pages/coming-soon.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    startApp() {
      try {
        this.loginPrompted = true
        if (!this.$authService.willTokenExpire()) {
          this.$router.push({ name: 'home' })
        } else {
          this.$authService
            .login()
            .then(
                () => {}, 
                (error) => {
                  this.$http.ressifnet.utils.Logger(error.stack, 'error')
                  this.loginPrompted = false
                  if (error.message === 'Network Error') {
                        this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Erreur de configuration',
                            icon: 'WifiOffIcon',
                            text: 'Impossible de contacter le serveur d\'identité',
                            variant: 'danger',
                        },
                        },
                        {
                        timeout: 800000,
                        position: 'top-center',
                  })
                  } else {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'XIcon',
                            text: error.message,
                            variant: 'danger',
                        },
                        },
                        {
                        timeout: 800000,
                        position: 'top-center',
                        })
                  }
            })
        }
      } catch (error) {
        this.loginPrompted = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
